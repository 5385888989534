import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

import { Benefits } from './Benefits'
import { Head } from './Head'
import { Includes } from './Includes'
import { Sidebar } from './Sidebar'
import { Terms } from './Terms'

export interface Props {
  benefits: string[]
  benefitsTitle?: string
  description?: string
  discount?: string
  IBEID?: number
  includes?: string[]
  includesTitle?: string
  languageCode: string
  languagePrefix?: string | null
  requestButtonURL?: string
  subtitle?: string
  terms?: string
  title: string
  validity?: string
}

export const OfferIntro = memo(function OfferIntro({
  benefits,
  benefitsTitle,
  description,
  discount,
  IBEID,
  includes,
  includesTitle,
  languageCode,
  languagePrefix,
  requestButtonURL,
  subtitle,
  terms,
  title,
  validity,
}: Props) {
  return (
    <Container>
      <Head discount={discount} validity={validity} />

      <Wrapper row stretch wrap>
        <LeftSide>
          <Title>{title}</Title>
          {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
          {description ? (
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          ) : null}

          <Includes list={includes} title={includesTitle} />

          <Terms description={terms} languageCode={languageCode} />
        </LeftSide>
        <RightSide>
          <Sidebar
            IBEID={IBEID}
            languageCode={languageCode}
            languagePrefix={languagePrefix}
            requestButtonURL={requestButtonURL}
          />

          <Benefits list={benefits} title={benefitsTitle} />
        </RightSide>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section``

const Wrapper = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
`

const LeftSide = styled.div`
  width: 50%;
  padding: 5rem 6.667vw;

  @media (max-width: 1023px) {
    width: 100%;
    padding: 3.75rem 1.875rem;
  }
`

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.875rem;
  line-height: 2.25rem;
`

const Subtitle = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  font-weight: 300;
  letter-spacing: 0.1062rem;
  line-height: 1.25rem;
  margin-top: 0.5rem;
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  font-weight: 300;
  line-height: 2rem;
  margin-top: 2.5rem;

  @media (max-width: 1023px) {
    margin-top: 1.875rem;
  }
`

const RightSide = styled.div`
  width: 50%;
  border-left: 0.125rem solid
    ${({ theme }) => theme.colors.variants.neutralLight2};
  padding: 5rem 6.667vw 5rem 8.056vw;

  @media (max-width: 1023px) {
    width: 100%;
    border-left: 0;
    padding: 0 1.875rem 3.75rem;
  }
`
